import React, { useRef, useLayoutEffect, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { themeGet, backgroundColor, paddingTop } from 'styled-system';
import { Box } from '@qga/roo-ui/components';
import { mediaQuery } from 'lib/styledSystem';
import { rem } from 'polished';
import SkipToContentButton from 'components/SkipToContentButton';
import { noop } from 'lodash';

export const DropdownBase = styled(Box)`
  position: relative;
  margin-top: 0;
  padding-top: ${themeGet('space.4')};
  right: auto;
  box-shadow: none;
  border: none;
  background: ${themeGet('colors.greys.porcelain')};
  z-index: ${(props) => (props.zIndex ? props.zIndex : themeGet('zIndices.modalContent'))};
  ${backgroundColor};
  ${paddingTop};

  ${mediaQuery.minWidth.sm} {
    position: absolute;
    margin-top: ${themeGet('space.2')};
    margin-bottom: ${themeGet('space.2')};
    padding-top: 0;
    ${(props) =>
      props.anchorX === 'right' &&
      css`
        right: 0;
      `}

    border: 1px solid ${themeGet('colors.greys.alto')};
    box-shadow: ${themeGet('shadows.modal')};
  }
`;

interface DropdownProps {
  children?: React.ReactNode;
  viewThreshold: number;
  width?: string[] | string | number;
  skipToContent?: string;
  cancelModal: () => void;
  anchorX: string;
  backgroundColor: string;
  paddingTop: number[];
  borderRadius: string;
  zIndex?: number;
}

const Dropdown = ({
  viewThreshold = 0.5,
  children,
  skipToContent = undefined,
  cancelModal = noop,
  width = ['100%', rem('530px'), rem('750px')],
  zIndex,
  ...rest
}: DropdownProps) => {
  const [position, setPosition] = useState({});
  const dropdownRef = useRef<HTMLSelectElement>(null);

  useLayoutEffect(() => {
    if (!dropdownRef.current || !window) return;
    const { top, height } = dropdownRef.current.getBoundingClientRect();
    const canDisplayBelow = height * viewThreshold + top < window.innerHeight;
    // the viewThreshold represents how much of the dropdown must fit onscreen before it inverts and
    // is shown above the parent. By default, if less than 50% of the dropdown is visible it will invert

    setPosition(canDisplayBelow ? { top: '100%' } : { bottom: '100%' });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DropdownBase ref={dropdownRef} {...rest} {...position} width={width} zIndex={zIndex}>
      {skipToContent && (
        <SkipToContentButton as="a" href={skipToContent} onClick={cancelModal}>
          Skip to content
        </SkipToContentButton>
      )}
      {children}
    </DropdownBase>
  );
};

export default Dropdown;
