import React, { useMemo, useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { subDays, format as dateFnsFormat, addDays } from 'date-fns';
import { useDataLayer } from 'hooks/useDataLayer';
import { Flex, Box, Link } from '@qga/roo-ui/components';
import isEqual from 'lodash/isEqual';
import every from 'lodash/every';
import includes from 'lodash/includes';
import noop from 'lodash/noop';
import { useBreakpoints } from 'hooks/useBreakpoints';
import ResponsiveModal from 'components/ResponsiveModal';
import Calendar from './Calendar';
import DateDisplay from './DateDisplay';
import PhoneDateDisplay from './PhoneDateDisplay';
import { FOCUS } from './constants';
import Dropdown from 'components/Dropdown';
import { CancelButton, ConfirmButton, FilterWrapper, StickyFooter, Actions, StickyHeader } from './DatePicker.style';
import { fetchCalendar, clearCalendar } from 'store/calendar/calendarActions';
import { getFirstCalendarDay, getLastCalendarDay } from 'lib/localDate/utils';
import { getExclusiveOfferRoomTypes } from 'store/exclusiveOffer/exclusiveOfferSelectors';
import { getIsExclusive, getPropertyId } from 'store/property/propertySelectors';
import { rem } from 'polished';
import { SEARCH_DATE_FORMAT } from 'config';
import { getPageName, getQueryRoomTypeId } from 'store/router/routerSelectors';
import { useRouter } from 'next/router';
import Select from './Select';
import Filter from './Select/components/Filter';
import StaySummary from 'components/StaySummary';
import OfferTermsModal from './OfferTermsModal';
import { getIsMobileApp } from 'store/ui/uiSelectors';
import debounce from 'lodash/debounce';
import { clearResults } from 'store/propertyAvailability/propertyAvailabilityActions';

export interface AvailabilityDatePickerProps {
  selectedDates: { startDate?: Date; endDate?: Date };
  labelOptions: object;
  updateQuery: (value: { checkIn?: Date; checkOut?: Date }) => void;
  anchorX: 'left' | 'right';
  viewThreshold?: number;
  submitOnChange?: boolean;
  minDate?: Date;
  isHomepage?: boolean;
  hasEndDate?: (value: Date) => void;
  initialDisplayDate?: Date;
  boundaries?: { startDate?: Date; endDate?: Date };
  error?: boolean;
  maxSelectableDays?: number;
  clearSelectedDates: () => void;
  isLimited: boolean;
  backgroundColor?: string;
  isOptional?: boolean;
  zIndex?: number;
}

const AvailabilityDatePicker = ({
  selectedDates = { startDate: undefined, endDate: undefined },
  labelOptions = {},
  updateQuery,
  anchorX = 'right',
  viewThreshold = 0.5,
  submitOnChange = true,
  minDate = subDays(new Date(), 1),
  isHomepage = false,
  hasEndDate = noop,
  initialDisplayDate = new Date(),
  boundaries = { startDate: undefined, endDate: undefined },
  error = false,
  maxSelectableDays = undefined,
  clearSelectedDates,
  isLimited = false,
  backgroundColor = 'white',
  isOptional = false,
  zIndex,
}: AvailabilityDatePickerProps) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { emitInteractionEvent } = useDataLayer();
  const [nextDates, setNextDates] = useState(selectedDates);
  const [showMenu, setShowMenu] = useState(false);
  const [focusedInput, setFocusedInput] = useState(FOCUS.NONE);
  const [monthsToDisplay, setMonthsToDisplay] = useState<string[]>([]);
  const [offerIdSelected, setOfferIdSelected] = useState<string | undefined>();
  const { isLessThanBreakpoint } = useBreakpoints();
  const startDate = getFirstCalendarDay(selectedDates.startDate) as string;
  const endDate = getLastCalendarDay(selectedDates.startDate) as string;
  const propertyId = useSelector(getPropertyId);
  const isExclusive = useSelector(getIsExclusive);
  const payload = useMemo(
    () => ({ propertyId, startDate, endDate, luxeOnly: !!isExclusive }),
    [propertyId, startDate, endDate, isExclusive],
  );
  // eslint-disable-next-line no-unused-vars
  const roomTypeId = useSelector(getQueryRoomTypeId); // Pass this to the calendar availability fetcher
  const hash = (typeof window !== 'undefined' && window?.location?.hash) || '';
  const exclusiveOfferRoomTypes = useSelector(getExclusiveOfferRoomTypes);
  const mappedExclusiveOfferRoomTypes = exclusiveOfferRoomTypes.map(({ id, extranet }) => ({ value: id, text: extranet.name }));
  const isMobileApp = useSelector(getIsMobileApp);
  const isSearchPage = useSelector(getPageName) === 'search-list';

  const container = useRef<HTMLDivElement>();

  const isMobile = isLessThanBreakpoint(0);

  useEffect(() => {
    if (hash === '#rooms' || hash === '#check-availability') {
      setFocusedInput(FOCUS.START_DATE);
    }
  }, [hash, setFocusedInput]);

  useEffect(() => {
    setNextDates(selectedDates);
  }, [selectedDates]);

  const totalMonthsMobile = useMemo(() => {
    if (monthsToDisplay.length < 2) {
      return 2;
    } else {
      return monthsToDisplay.length + 1;
    }
  }, [monthsToDisplay]);

  const isDirty = useCallback(() => !isEqual(nextDates, selectedDates), [nextDates, selectedDates]);

  const isValid = useCallback(() => every(nextDates, (date) => date instanceof Date), [nextDates]);

  const onSelectedChange = (id) => {
    const roomType = exclusiveOfferRoomTypes.find((rt) => rt?.id === id);
    const offerId = roomType?.offerId;
    setOfferIdSelected(offerId);

    const payload = {
      propertyId,
      offerId: offerId,
      startDate,
      endDate,
      luxeOnly: !!isExclusive,
    };

    if (!isLimited) {
      if (isMobile) {
        container.current
          ?.querySelector('[data-testid="calendar-month"]:nth-child(2) > span')
          ?.scrollIntoView({ block: 'start', behavior: 'instant' });
        const newMonthsToDisplay = Array.from({ length: isLessThanBreakpoint(0) ? 3 : 2 }, (_, index) => {
          const startDateObj = new Date(startDate);
          const currentMonthDate = new Date(startDateObj.getFullYear(), startDateObj.getMonth() + index, 1);
          return dateFnsFormat(currentMonthDate, SEARCH_DATE_FORMAT);
        });
        setMonthsToDisplay(newMonthsToDisplay);
      } else {
        setMonthsToDisplay([]);
      }
      dispatch(clearCalendar());
      dispatch(fetchCalendar(payload));
    }
    router.replace(
      {
        query: { ...router.query, roomTypeId: roomType?.id },
      },
      undefined,
      { shallow: true },
    );
  };

  const applyChange = useCallback(() => {
    updateQuery({
      checkIn: nextDates.startDate,
      checkOut: nextDates.endDate,
    });
    emitInteractionEvent({
      type: 'Availability Calendar',
      value: 'Apply changes',
    });
  }, [updateQuery, nextDates, emitInteractionEvent]);

  const cancelChange = useCallback(() => {
    setFocusedInput(FOCUS.NONE);
    setNextDates(selectedDates);
  }, [setFocusedInput, setNextDates, selectedDates]);

  const nextInputFocus = useCallback(
    ({ startDate, endDate }) => {
      const noEndDateSet = endDate === null;
      const isUpdatingStartDate = startDate && !isEqual(startDate, nextDates.startDate);

      return noEndDateSet || isUpdatingStartDate ? FOCUS.END_DATE : FOCUS.NONE;
    },
    [nextDates],
  );

  const handleFocusedInput = useCallback(
    ({ openModal, isOpen }) =>
      (nextFocusedInput: React.SetStateAction<string>) => {
        emitInteractionEvent({
          type: 'Availability Calendar',
          value: 'Open Calendar',
        });
        const numMonths = isLessThanBreakpoint(0) ? totalMonthsMobile : 2;
        const newMonthsToDisplay = Array.from({ length: numMonths }, (_, index) => {
          const startDateObj = new Date(startDate);
          const currentMonthDate = new Date(startDateObj.getFullYear(), startDateObj.getMonth() + index, 1);
          return dateFnsFormat(currentMonthDate, SEARCH_DATE_FORMAT);
        });

        setMonthsToDisplay(newMonthsToDisplay);
        setFocusedInput(nextFocusedInput);
        openModal();
        if (!isLimited && !isOpen) {
          dispatch(fetchCalendar(payload));
        }
      },

    [setFocusedInput, dispatch, payload, isLessThanBreakpoint, startDate, totalMonthsMobile, emitInteractionEvent, isLimited],
  );

  const handleApply = useCallback(
    (event) => {
      if (event && !submitOnChange) event.preventDefault();
      if (!isDirty()) {
        dispatch(clearCalendar());
        setFocusedInput(FOCUS.NONE);
        return;
      }
      if (isValid() || (isHomepage && nextDates.endDate === null)) {
        applyChange();
      }
      if (typeof nextDates.endDate !== 'undefined') hasEndDate(nextDates.endDate);
      setFocusedInput(FOCUS.NONE);
    },
    [isDirty, applyChange, isValid, nextDates, submitOnChange, dispatch, isHomepage, hasEndDate],
  );

  const handleViewAllRoom = useCallback(() => {
    setOfferIdSelected(undefined);
    if (isMobile) {
      container.current
        ?.querySelector('[data-testid="calendar-month"]:nth-child(2) > span')
        ?.scrollIntoView({ block: 'start', behavior: 'instant' });
      const newMonthsToDisplay = Array.from({ length: isLessThanBreakpoint(0) ? 3 : 2 }, (_, index) => {
        const startDateObj = new Date(startDate);
        const currentMonthDate = new Date(startDateObj.getFullYear(), startDateObj.getMonth() + index, 1);
        return dateFnsFormat(currentMonthDate, SEARCH_DATE_FORMAT);
      });
      setMonthsToDisplay(newMonthsToDisplay);
    } else {
      setMonthsToDisplay([]);
    }
    dispatch(clearCalendar());
    dispatch(fetchCalendar(payload));

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { roomTypeId: _, ...rest } = router.query;
    router.replace(
      {
        query: rest,
      },
      undefined,
      { shallow: true },
    );

    emitInteractionEvent({
      type: 'Availability Calendar',
      value: 'View all room button selected',
    });
  }, [router, dispatch, payload, emitInteractionEvent, startDate, isLessThanBreakpoint, isMobile]);

  const handleCancel = useCallback(() => {
    cancelChange();
    dispatch(clearCalendar());
    clearSelectedDates();
    setFocusedInput(FOCUS.NONE);
    emitInteractionEvent({
      type: 'Availability Calendar',
      value: 'Close calendar',
    });
  }, [cancelChange, dispatch, clearSelectedDates, emitInteractionEvent]);

  const handleChangeDateRange = useCallback(
    (dates) => {
      const nextFocusedInput = nextInputFocus(dates);
      setNextDates(dates);
      setFocusedInput(nextFocusedInput);
    },
    [nextInputFocus],
  );

  const onMonthScrolled = debounce(({ startOfMonthString, endOfMonthString }) => {
    if (!includes(monthsToDisplay, startOfMonthString)) {
      setMonthsToDisplay([...monthsToDisplay, startOfMonthString]);

      const todayString = dateFnsFormat(new Date(), SEARCH_DATE_FORMAT);
      const isStartOfMonthBeforeToday = startOfMonthString.localeCompare(todayString) < 0;

      const monthPayload = {
        propertyId,
        offerId: offerIdSelected,
        startDate: isStartOfMonthBeforeToday ? todayString : startOfMonthString,
        endDate: dateFnsFormat(addDays(new Date(endOfMonthString), 1), SEARCH_DATE_FORMAT),
        luxeOnly: false,
      };
      if (!isLimited) {
        dispatch(fetchCalendar(monthPayload));
      }
    }
  }, 200);

  const onClearDates = useCallback(() => {
    setFocusedInput(FOCUS.NONE);
    if (!isSearchPage) {
      updateQuery({
        checkIn: undefined,
        checkOut: undefined,
      });
      dispatch(clearResults());
    }
    setNextDates({ startDate: undefined, endDate: undefined });
    emitInteractionEvent({
      type: 'Availability Calendar',
      value: 'Clear Calendar',
    });
  }, [dispatch, emitInteractionEvent, isSearchPage, setNextDates, updateQuery]);

  const [showModal, setShowModal] = useState(false);

  return (
    <ResponsiveModal
      title="Select dates"
      onSubmit={handleApply}
      onCancel={handleCancel}
      onBlur={handleApply}
      closeOnUpdate={!showMenu}
      enableHeader={!showModal}
      isSubmitOnCancel={true}
      enableFooter={false}
      enableScroll={!showMenu}
      submitButtonText={''}
      onOpenModal={noop}
      backgroundColor={['white', 'greys.porcelain']}
    >
      {({ isOpen, openModal, submitModal }) => (
        <>
          <Box position="relative" ref={container}>
            <PhoneDateDisplay
              display={['block', 'block', 'none']}
              range={nextDates}
              handleFocusedInput={handleFocusedInput({ openModal })}
              labelOptions={labelOptions}
              error={error}
              isOptional={isOptional}
            />

            <DateDisplay
              display={['none', 'none', 'flex']}
              range={nextDates}
              handleFocusedInput={handleFocusedInput({ openModal, isOpen })}
              focusedInput={focusedInput}
              labelOptions={labelOptions}
              error={error}
              backgroundColor={backgroundColor}
              isOptional={isOptional}
            />
            {isOpen && (
              <Dropdown
                anchorX={anchorX}
                viewThreshold={viewThreshold}
                skipToContent="#main-content"
                backgroundColor="white"
                paddingTop={[0, 4]}
                cancelModal={() => true}
                width={['100%', rem('750px')]}
                borderRadius={rem('10px')}
                zIndex={zIndex}
              >
                <StickyHeader showMenu={showMenu} isMobileApp={isMobileApp} isLimited={isLimited} width="100%">
                  <Flex justifyContent="flex-start">
                    <StaySummary checkIn={nextDates.startDate} checkOut={nextDates.endDate} />
                  </Flex>
                  <FilterWrapper>
                    <Box pr={2} width={['46%', '100%']}>
                      {!isLimited && (
                        <Filter
                          data-testid="view-all-room-box"
                          onClick={handleViewAllRoom}
                          pr={4}
                          active={typeof roomTypeId !== 'string'}
                          alignItems="center"
                          menuAlign="center"
                        >
                          View all rooms
                        </Filter>
                      )}
                    </Box>
                    {!isLimited && (
                      <Select
                        value={roomTypeId}
                        options={mappedExclusiveOfferRoomTypes}
                        placeholder={'Filter by rooms'}
                        height={'48px'}
                        ellipsis
                        menuAlign="center"
                        alignItems=""
                        onSelectedChange={onSelectedChange}
                        setShowMenu={setShowMenu}
                        showMenu={showMenu}
                      />
                    )}
                    {isLimited && <Box height={['20px', '0px']} width="100%" />}
                  </FilterWrapper>
                </StickyHeader>
                <Box px={[0, 10, 10]} py={[0, 5, 5]} data-testid="date-range-picker" backgroundColor="white">
                  <Calendar
                    minDate={minDate}
                    initialDisplayDate={initialDisplayDate}
                    monthsToDisplay={isLessThanBreakpoint(0) ? totalMonthsMobile : 2}
                    startDate={nextDates.startDate}
                    endDate={nextDates.endDate}
                    onChangeDates={handleChangeDateRange}
                    focusedInput={focusedInput}
                    boundaries={boundaries}
                    maxSelectableDays={maxSelectableDays}
                    onMonthScrolled={onMonthScrolled}
                    isLimited={isLimited}
                  />
                </Box>
                {!isMobile && !isLimited && (
                  <Box px={8} py={6}>
                    <OfferTermsModal showModal={showModal} setShowModal={setShowModal} />
                  </Box>
                )}
                <StickyFooter>
                  {isMobile && !isLimited && (
                    <Box px={[4, 8]} py={6}>
                      <OfferTermsModal showModal={showModal} setShowModal={setShowModal} />
                    </Box>
                  )}
                  <Actions isMobileApp={isMobileApp}>
                    <CancelButton as={Link} onClick={onClearDates} data-testid="cancel-button">
                      Clear dates
                    </CancelButton>
                    {isValid() && (
                      <ConfirmButton onClick={submitModal} variant="primary" data-testid="done-button">
                        Confirm
                      </ConfirmButton>
                    )}
                  </Actions>
                </StickyFooter>
              </Dropdown>
            )}
          </Box>
        </>
      )}
    </ResponsiveModal>
  );
};

export default AvailabilityDatePicker;
